import React, { useState } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from "@material-ui/core/styles";

import { ThemeProvider } from '@material-ui/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

import { dispatchStyles } from '../../../modules/resources/styles'

import { colors } from '../../../modules/resources/colors'
import { mainTheme } from '../../../modules/resources/themes'
import { string_general } from '../../../modules/resources/strings'

import Dropdown from '../../components/Dropdown'
import MapContainer from './Map'
import Table from '../../components/Table'
import Button from '@material-ui/core/Button';

//Actions
import { setInputUser } from '../../../modules/core/User/actions'
import {
  clearState,
  getData
} from '../../../modules/core/Tracking/actions'


const styles = {
  root: {
    background: "black"
  },
  input: {
    color: "black",
    background: colors.bg_input
  }
}

class Tracking extends React.Component {


  state = {
    driversFilter: [],
    sitesFilter: [],
    numberPages: 100,
    loading: true,
    customerSelected: this.props.idCustomerSelected,
    customerlabel: '',
    typeSelected: 0,
    groupSelected: 0,
    groupLabel: 'All',

    driverSelected: 0,
    driverLabel: '',

    showMap: true,
    showTable: true,
    showModal: false,
    hoverKey: 0,

    showAlert: false,
    titleAlert: '',
    textContentAlert: '',
    onOkAlert: () => { },

    importing: false,
    csvReport: {
      data: [],
      headers: [
        { label: "Driver", key: "driverFullName" },
        { label: "Customer", key: "customer.customerName" },
        { label: "Last Seen", key: "lastSeen" },
        { label: "Last Location", key: "address" },
        { label: "Truck", key: "truck.truckNumber" }

      ],
      filename: 'Tracking.csv'
    }
  };

  componentDidMount() {
    this.props.clearState()
    this.props.getData({
      token: this.props.user.token,
      event: this.props.match.params.event,
      customer: this.state.customerSelected,
      history: this.props.history,
      loadDriver: false
    })

    this.props.setInputUser({
      prop: 'siteNavigations',
      value: [
        {
          link: `/events`,
          label: 'Events'
        },
        {
          link: `/events`,
          label: this.props.infoLabel
        },
        {
          link: `/event/${this.props.match.params.event}/tracking`,
          label: 'Tracking'
        }]
    })
  }

  downloadReport = (event, done) => {
    // API call to get data
    const objReport = {
      filename: 'Tracking.csv',
      headers: [
        { label: "Driver", key: "driverFullName" },
        { label: "Customer", key: "customer.customerName" },
        { label: "Last Seen", key: "lastSeen" },
        { label: "Last Location", key: "address" },
        { label: "Truck", key: "truck.truckNumber" }

      ],
      data: this.props.drivers
    };
    this.setState({ csvReport: objReport }, () => {
      done();
    });
  }

  reloadPins = () => {
    this.props.getData({
      token: this.props.user.token,
      event: this.props.match.params.event,
      customer: this.state.customerSelected,
      loadDriver: true
    })

    this.setState({ loading: false })
  }

  render() {
    const idCustomer = this.state.customerSelected
    const idDriver = this.state.driverSelected
    let driversFilter = [];

    if (this.props.drivers.length > 0) {
      driversFilter = idCustomer === 0 ? this.props.drivers :
        this.props.drivers.filter(e => e.customer.idCustomer === idCustomer);
    }

    let sitesFilter = idCustomer === 0 ? this.props.sites :
      this.props.sites.filter(e => e.idCustomer === idCustomer)
    let driversTemp = [{ value: 0, description: 'All' }]

    if (driversFilter !== undefined) {
      driversFilter.forEach(d => driversTemp.push({
        value: d.id,
        description: d.driverFullName,
        idCustomer: d.customer.idCustomer
      }))
    }

    let driverFilter2 = idDriver === 0 ?
      driversFilter :
      driversFilter.filter(dr => dr.id === idDriver)
    let sitesFilter2 = idDriver === 0 ?
      sitesFilter :
      sitesFilter.filter(si => si.driversId.includes(idDriver))

    return (
      <div style={dispatchStyles.container}>
        <div style={dispatchStyles.containerFilters}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '30%',
              marginRight: 10,
              marginTop: 3
            }} >

            <div style={dispatchStyles.containerDropdownFilters}>
              {/* CUSTOMERS */}
              <label>{string_general.label_dropdown_customers} </label>
              <Dropdown
                selectItem={this.state.customerSelected}
                placeholder={this.props.labelCustomers}
                disabled={this.props.idCustomerSelected !== 0}
                options={this.props.customers}
                onChange={(value, description) => {
                  this.setState({
                    customerSelected: value,
                    customerlabel: description,
                    driverSelected: 0,
                    driverLabel: ''
                  })
                }} />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '30%',
              marginRight: 10,
              marginTop: 3
            }} >

            {/* DRIVERS */}
            <div style={dispatchStyles.containerDropdownFilters}>
              {(this.state.customerSelected === 0) ? null :
                <>
                  <label>{string_general.label_dropdown_drivers} </label>
                  <Dropdown
                    selectItem={this.state.driverSelected}
                    placeholder={this.state.driverLabel}
                    options={driversTemp}
                    onChange={(value, description) => {
                      this.setState({
                        driverSelected: value,
                        driverLabel: description
                      })
                    }} />
                </>
              }
            </div>
          </div>

          <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '20%',
            marginRight: 10,
            marginTop: 3
          }}>

            <Button variant="contained" color="primary"
              onClick={() => this.reloadPins()}
            >
              Pull Locations
            </Button>
          </div>
        </div>

        {/* MAP */}
        <div style={{ height: '100%', width: '100%' }}>

          {this.state.importing && <ThemeProvider theme={mainTheme}>
            <LinearProgress color="primary" />
          </ThemeProvider>}

          <MapContainer
            history={this.props.hisotry}
            event={this.props.match.params.event}
            drivers={driverFilter2}
            sites={sitesFilter2}
            customerSelected={this.state.customerSelected}
            hoverKey={this.state.hoverKey}
            onChangeHoverKey={(key) => this.setState({ hoverKey: key })}
          />
        </div>
        {(this.state.loading) ? null :
          <Table
            numberPages={this.state.numberPages}
            loading={this.state.loading}
            columns={this.props.columnsWithoutSortable}
            items={driverFilter2} />}
      </div>
    )
  }
}

const mapStateProps = (state) => {
  const { isAuthenticated, user, idCustomerSelected, infoLabel } = state.user
  const { drivers, customers, labelCustomers, sites, driversTemp, columnsWithoutSortable } = state.tracking

  return {
    isAuthenticated, user, idCustomerSelected, infoLabel,
    drivers, customers, labelCustomers, sites, driversTemp, columnsWithoutSortable
  }
}

export default withStyles(styles)(withRouter(connect(mapStateProps, {
  clearState,
  getData,
  setInputUser
})(Tracking)))
